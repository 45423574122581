<template>
  <div class="grid grid-cols-2 gap-x-6 sm:gap-x-8">
    <div>
      <h3 class="text-sm font-medium leading-6 text-gray-500 px-2">
        Free Tools
      </h3>
      <div class="mt-6 flow-root">
        <div class="-my-2">
          <div v-for="(tool, index) in freeTools" :key="index">
            <nuxt-link
              :to="tool.href"
              class="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900 rounded-lg p-2 hover:bg-gray-50"
            >
              {{ tool.text }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h3 class="text-sm font-medium leading-6 text-gray-500 px-2">
        Understand your business
      </h3>
      <div class="mt-6 flow-root">
        <div class="-my-2">
          <div v-for="(item, index) in runYourBusiness" :key="index">
            <nuxt-link
              :to="item.href"
              class="flex gap-x-4 py-2 text-sm font-semibold leading-6 text-gray-900 rounded-lg p-2 hover:bg-gray-50"
            >
              {{ item.text }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const freeTools = [
  { href: '/invoice-generator', text: 'Invoice Generator' },
  { href: '/audit-analyzer', text: 'Audit Analyzer' },
  { href: '/company-search', text: 'Company Search' },
  { href: '/margin-calculator', text: 'Margin Calculator' },
  { href: '/margin-scheme-calculator', text: 'Margin Scheme Calculator' },
]

const runYourBusiness = [
  { href: '/guides', text: 'Guides' },
  { href: '/glossary', text: 'Accounting glossary' },
  /*
  { href: '#', text: 'Set up a company in Cyprus.' },
      */
  { href: '/accountant-directory', text: 'Find an accountant in Cyprus' },
]
</script>
