<template>
  <nuxt-link
    :to="url"
    :title="text"
    :class="linkClass"
    class="flex gap-3 border border-transparent rounded-md px-5 py-3 text-base font-semibold shadow-sm"
  >
    <span>{{ text }}</span>
  </nuxt-link>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: 'Button',
  },
  url: {
    type: String,
    default: '/',
  },
  variant: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'md',
  },
})

const linkClass = computed(() => {
  if (props.variant === 'primary') {
    return 'border border-transparent rounded-md bg-violet-700 text-white hover:bg-violet-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-900'
  } else if (props.variant === 'secondary') {
    return 'rounded-md bg-gray-25 text-gray-900 ring-1 ring-inset ring-gray-300 hover:border-white'
  } else if (props.variant === 'link') {
    return 'rounded-md text-white opacity-60 hover:border hover:border-violet-900 hover:opacity-100'
  } else if (props.variant === 'tertiary') {
    return 'rounded-md text-white border border-2 border-violet-700 hover:bg-pink-600 hover:border-pink-600'
  } else if (props.variant === 'black') {
    return 'rounded-md text-white bg-gray-900 border border-2 border-gray-900 hover:bg-gray-800 hover:border-gray-800'
  } else {
    return 'flex overflow-hidden gap-1.5 justify-center items-center text-base font-semibold leading-none'
  }
})
</script>
