import { default as _91_46_46_46app_93j6IBojpW6xMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/[...app].vue?macro=true";
import { default as indexeTl4GSgAMmMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/accountant-directory/index.vue?macro=true";
import { default as _91code_93Ogq5VqdiAMMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/audit/[code].vue?macro=true";
import { default as _91slug_93eidH6iGqJAMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/blog/[slug].vue?macro=true";
import { default as _91slug_930dQQfbKrjsMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/blog/category/[slug].vue?macro=true";
import { default as indexcZJaUHi6IcMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/blog/index.vue?macro=true";
import { default as _91slug_93yVtLytKwksMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/company/[slug].vue?macro=true";
import { default as _91slug_93pef7WFpo9dMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/glossary/[slug].vue?macro=true";
import { default as index9IxcmF2TNKMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/glossary/index.vue?macro=true";
import { default as _91slug_93l5y69GMQHIMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/tutorials/[slug].vue?macro=true";
import { default as indexLRoX7DmqFdMeta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/tutorials/index.vue?macro=true";
import { default as component_45stubT4fuj2VOa9Meta } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubT4fuj2VOa9 } from "/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "app",
    path: "/:app(.*)*",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/[...app].vue")
  },
  {
    name: "accountant-directory",
    path: "/accountant-directory",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/accountant-directory/index.vue")
  },
  {
    name: "audit-code",
    path: "/audit/:code()",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/audit/[code].vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/blog/[slug].vue")
  },
  {
    name: "blog-category-slug",
    path: "/blog/category/:slug()",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/blog/category/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/blog/index.vue")
  },
  {
    name: "company-slug",
    path: "/company/:slug()",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/company/[slug].vue")
  },
  {
    name: "glossary-slug",
    path: "/glossary/:slug()",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/glossary/[slug].vue")
  },
  {
    name: "glossary",
    path: "/glossary",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/glossary/index.vue")
  },
  {
    name: "tutorials-slug",
    path: "/tutorials/:slug()",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/tutorials/[slug].vue")
  },
  {
    name: "tutorials",
    path: "/tutorials",
    component: () => import("/codebuild/output/src1452167408/src/cybooks-marketing-boilerplate/pages/tutorials/index.vue")
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/posts-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/glossary-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/companies_1-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/companies_2-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/companies_3-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/companies_4-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/companies_5-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  },
  {
    name: component_45stubT4fuj2VOa9Meta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubT4fuj2VOa9
  }
]