export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  const api = $fetch.create({
    baseURL: runtimeConfig.public.apiBase,
  })

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  }
})
